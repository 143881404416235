.backgroundVideo {
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 90%;
  object-fit: cover;
  align-items: center;
}

.Header {
  display: grid;
  padding: 8.3rem 8.3rem 3.3rem;
  margin-bottom: 0;
  align-items: center;
  gap: 32px;
  // background-image: url("https://i.ibb.co/Zz2F5KH/7.jpg");
  // background-image: url("https://drive.google.com/uc?id=1tky_DspWbSVD5Rl1LFd3xbS6mNgYHQ9t");

  // background-image: url("https://i.ibb.co/C672wdQ/com-7.webp");
  // background-size: calc(100%);
  // background-position: center;
  // background-repeat: no-repeat;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.desc {
  background-color: transparent;
}

.title {
  background-color: transparent;

  img {
    width: 30rem;
    background-color: transparent;
  }
}

.content {
  bottom: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
  background-color: transparent;
  align-items: center;
  position: relative;
  z-index: 1;
} 

@media only screen and (max-width: 1200px) {
  .Header {
    padding: 0 3.3rem 0;
    text-align: center;
  }

  .content {
    align-items: center;
    top: -70%;
  }


  .title {
    background-color: transparent;
    direction: ltr;

    img {
      direction: ltr;
      
      width: 50vw;
      background-color: transparent;
    }
  }

}