

.description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
}

@media only screen and (max-width: 1200px) {

    .contentBox {
        gap: 10px;
    }

    .content,
    .contentBox {
        line-height: 0;
    }

    .title {
        font-size: 35px;
        line-height: 30px;
    }
}