.title {
    color: var(--heading-color);
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
}

.platforms {
    display: grid;
    gap: 13rem;
    padding: 0 4rem 0 4rem;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    justify-items: stretch;
    grid-template-columns: repeat(4, 1fr);
}


.heading,
.heading a {
    text-decoration: none;
    color: var(--darkblue);
    cursor: pointer;
}

a:focus {
    outline: none;
}

.icon {
    display: grid;
    text-align: center;
    gap: 0.7rem;
}

.telephones {
    text-align: center;
}

.contactPhone {
    padding: 2rem 4rem;

    a {
        font-size: large;
        font-weight: bolder;
    }
}

#closeDialog {
    display: none;
}


@media only screen and (max-width: 1200px) {

    .section {
        margin: 2rem 0 3rem;
    }

    .platforms {
        display: grid;
        gap: 3rem;
        justify-items: stretch;
        grid-template-columns: repeat(2, 1fr);

    }

    .icon p {
        font-size: smaller;
    }

    .title {
        font-size: 23px;
        line-height: 30px;
    }

}