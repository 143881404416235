.heading {
    color: var(--heading-color);
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
}

.container {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
}

.container span {
    text-align: center;
    i {
        margin-bottom: 1rem;
        color: #d1a025;
    }
}

.title {
    color: var(--heading-color);
    font-size: 1.5rem;
    font-weight: 500;
}

svg {
    fill: #d1a025;
    width: 36px;
    height: 36px;
    margin-bottom: 1rem;
}


@media only screen and (max-width: 1200px) {


    .title {
        font-size: 1.3rem;
    }

    .container {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
    }

    .container span {
        text-align: center;
        i {
            margin-bottom: 1rem;
            color: #d1a025;
        }
    }
}