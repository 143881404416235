@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap');

* {
  direction: rtl;
  /* font-family: Arial; */
  font-family: "Noto Sans Hebrew", sans-serif;

  background-color: #000000;
  color: white;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration-line: none;
  /* color: white; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}



.fixedIcons {
  display: flex;
}

.whatsapp-ico {
  fill: white;
  width: 40px;
  height: 40px;
  padding: 2px;
  background-color: #4dc247;
  border-radius: 50%;
  position: fixed;
  bottom: 5px;
  right: 20px;
  z-index: 10;
}

.waze-ico {
  fill: white;
  width: 40px;
  height: 40px;
  padding: 2px;
  background-color: #05c8f7;
  border-radius: 50%;
  position: fixed;
  bottom: 65px;
  right: 20px;
  z-index: 10;
}



.modal::backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  text-decoration: none;

  a:focus {
    outline: none;
  }
}

.modal {
  min-width: 30rem;
  padding: 0;
  z-index: 2;
  border-radius: 15px;
  color: #d1a025;
  box-shadow: 0 2px 8px rgba(209, 160, 37, 0.2);
  animation: slide-down-fade-in 0.3s ease-out forwards;
}


section {
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 8rem;
  justify-content: space-evenly;
  align-content: stretch;
  gap: 10px;
}

:root {
  --primary: #d1a025;
  --heading-color: white;
  --bg-shade: #f0fff8;
  --github: #39594a;
  --darkblue: white;
  --black: #000000;
  --white: #ffffff;
}

.btn {
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.btn-outline-primary {
  color: black;
  background-color: #d1a025;
  border: 1px solid var(--white);
}

.btn-outline-primary:hover {
  color: #d1a025;
  background-color: white;
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}


hr {
  flex-grow: 1;
  /* Allows the <hr> to grow and fill the space */
  background-color: white;
  border: none;
  display: block;
  height: 2.2px;
  margin: 1.5rem 0;
}

.sectionTitle {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}

/* Portfolio & Contact Me */
.sub--title {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
}

#map {
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 8rem;
  justify-content: space-evenly;
  align-content: stretch;

}

.map-container {
  border-radius: 8px;
  height: 400px;
  color: white;
  background-color: white;
}

.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-top-right {
  background-color: transparent;
}

.mapboxgl-ctrl-icon {
  background-color: white;
}

@media only screen and (max-width: 1200px) {



  .modal::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }

  .modal {
    min-width: 20px;
    padding: 0;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  section {
    padding: 0 3.2rem 2rem 3.2rem;
  }

  .sectionTitle {
    font-size: 23px;
    line-height: 30px;
  }


  .btn-outline-primary {
    display: none;
  }

  .portfolio--section--container,
  .portfolio--container-box {
    display: flex;
    flex-direction: column;
  }

  .portfolio--container {
    align-items: center;
  }

  .portfolio--container-box {
    gap: 30px;
  }

  .contact--section {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }

  .contact--form--container {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .divider {
    margin: 20px;
  }
}