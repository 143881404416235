.navbar {
    z-index: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 5.3rem;
    background: var(--black);
    box-shadow: 0 0.3rem 5rem 0 rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.contactButton{
    box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.2);

}

.items>ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    text-decoration: none;
}

.items ul>li>a {
    text-decoration: none;
}

.content {
    color: var(--white);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;
    cursor: pointer;
}

.logo {
    cursor: default;
    display: flex;
    img {
        width: 250px;
    }
}

.ham {
    display: flex;
    width: 100%;
    direction: ltr;
}

.active {
    color: var(--white);
    font-weight: 700;
}


/* Hamburger menu  */

.navHamburger {
    display: none;
    width: 1.875rem;
    height: 1.413rem;
    flex-direction: column;
    justify-content: space-around;

}

.navHamburgerLine {
    display: block;
    height: 0.188rem;
    width: 100%;
    background-color: white;
    border-radius: 0.625rem;
    transition: all ease-in-out 0.2s;
}


@media screen and (max-width: 1200px) {

    .navbar {
        padding: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .logo {
        img {
            width: 150px;
        }
    }

    .contactButton{
        display: none;
    }

    .navHamburger {
        display: flex;
        z-index: 200;
    }

    .items {
        display: flex;
    }

    .items {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -40rem;
        left: 0;
        width: 100%;
        transition: all ease-in-out 0.4s;
    }

    .items ul {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4rem 0 0.6rem;
        z-index: -100;
    }

    .items ul li {
        text-align: center;
    }

    .items ul li a {
        padding: 0.5rem;
    }

    .items {
        z-index: -1000;
    }

    .items.active {
        top: 1.8rem;
    }

    .navHamburger.active :nth-child(1) {
        transform: rotate(45deg) translate(0.45rem, 0.1875rem);
    }

    .navHamburger.active :nth-child(2) {
        opacity: 0;
    }

    .navHamburger.active :nth-child(3) {
        transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
    }
}
