@keyframes in {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.4;
    }

    75% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes out {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.4;
    }

    75% {
        opacity: 0.2;
    }

    100% {
        opacity: 0;
    }
}


.toRight {
    transform: translateX(50%);
    transition: transform opacity 2s;
    animation-name: out;
    animation-duration: 3.15s;
    animation-delay: -2s;
}

.toLeft {
    transform: translateX(-50%);
    transition: transform opacity 2s;
    animation-name: out;
    animation-duration: 3.15s;
    animation-delay: -2s;
}

.removed {
    opacity: 0;
}

.animation {
    transition: transform 2s;
}



/*****next and previous buttons*****/

.buttons {
    display: flex;
    justify-content: center;
    text-align: center;
}

.buttons button {
    margin: 0 2rem;
}

#button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

#button.wider {
    width: 5rem;
    height: auto;
}

#button.wider .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
}

#button.wider .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}

#button.wider .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

#button.wider .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}

#button:hover .circle {
    width: 100%;
}

#button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
}

.previous {
    rotate: 180deg;
}


/*******/


.containerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sectionContainer {
    width: 90%;
    list-style: none;
    margin: 2rem auto;
    padding: 1rem;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 2rem;

}

.title {
    color: var(--heading-color);
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
}

.card {
    overflow: hidden;
    text-align: center;
    min-height: 50vh;
}

.card img {
    margin: 1rem auto;
    object-fit: fill;
    max-height: 100vh;
    min-height: 100vh;
}

.card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 0.75rem;
    margin-bottom: 1.7rem;
}

.portfolioLink {
    margin: 1.2rem 0;
    text-decoration: none;
    font-weight: 600;
}

.action {
    display: inline-block;
    border-bottom: 1.33px solid var(--primary);
    padding-bottom: 10.6px;
}


@media only screen and (max-width: 1200px) {

    .sectionContainer {
        width: auto;
        margin: 2rem 0 0 0;
        padding: 0;
        display: grid;
        grid-template-columns: auto;
        align-items: center;
        gap: 0;
    }

    .containerBox {
        display: flex;
        // flex-direction: column;
    }

    .title {
        font-size: 23px;
        line-height: 30px;
    }

    .container {
        align-items: center;
    }

    .card img {
        width: 100%;
        margin: 1rem auto;
        object-fit: contain;
        max-height: 60vh;
        min-height: 60vh;
    }

    .containerBox {
        gap: 30px;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .buttons {
        display: flex;
    }
}