.reviews {
    padding: 2rem 2rem 0 2rem;
}

.reviewItem {
    line-height: 2.4rem;
    font-style: italic;
    font-size: 1.2rem;
}

.quoteMark {
    font-size: 1.8rem;
    margin: 0;
}